<template>
  <b-container class="wrapper__container form__container">
    <b-img class="woman__image" :src="require('@/assets/woman.png')"></b-img>
    <b-container class="bg__color">
      <b-container class="success_message_container">
        <h1 class="mb-2">
          <b-icon-emoji-smile></b-icon-emoji-smile> Überprüfung erfolgreich!
        </h1>
        Ihre Kreditwürdigkeit war erfolgreich, Sie können nun weiter zum
        Kreditantrag!
      </b-container>

      <b-container class="p-0 mt-3 text-center">
        <b-button class="success__button" size="lg" @click="finished"
          >Jetzt zum Kreditantrag!</b-button
        >
      </b-container>
    </b-container>
    <b-container class="footer__container">
      <b-img :src="require('@/assets/certificate.png')" fluid></b-img>
    </b-container>
  </b-container>
</template>

<script>
export default {
  methods: {
    finished() {
      window.location.href = "https://kredit-antrag.tipptarif24.de/";
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-transform: uppercase;
  font-weight: bold;
}
.form__container {
  position: relative;
}
.success_message_container {
  border: 2px solid #699437;
  padding: 40px 40px;
  font-size: 13px;
  font-weight: bold;
  color: #699437;
}
.form__container {
  margin-top: 60px;
}
.success__button {
  text-transform: uppercase;
  font-weight: bold;
}
.woman__image {
  position: absolute;
  right: -210px;
  top: -90px;
  z-index: 999;
}
.footer__container {
  margin-top: 100px;
  padding: 0px 100px;
  margin-bottom: 30px;
}
@media (max-width: 992px) {
  h1 {
    margin-bottom: 30px !important;
  }
  .woman__image {
    display: none;
  }
  .footer__container {
    margin-top: 60px;
    padding: 10px;
    margin-bottom: 30px;
  }
  .success_message_container {
    text-align: center;
  }
  .success__button {
    width: 100%;
  }
}
</style>
